import * as React from 'react'
import styled from 'styled-components'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'

import { H4, Paragraph } from './typography'

export const MusicItem = ({ input }) => {
    return (
        <Wrapper to={`/music/${input.slug.current}`}>
            <GatsbyImage
                image={input.cover.asset.gatsbyImageData}
                alt={input.cover.alt}
                objectFit='cover'
            />
            <H4 margin="0" color="var(--color-primary-medium-shade)">{input.title}</H4>
            <Paragraph margin="0" color="var(--color-primary-dark-shade)">{input.artist}</Paragraph>
        </Wrapper>
    )
}

const Wrapper = styled(Link)`
    margin: var(--space-06);
    text-decoration: none;
    display: flex;
    flex-direction: column;
    gap: var(--space-02);
    max-width: 100%;
`