import * as React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'

import { Layout, PageTitle } from '../../components/layout'
import { MusicItem } from '../../components/MusicItem'

const MusicPage = () => {
    const data = useStaticQuery(graphql`
        {
            allSanityMusic(sort: { fields: releaseDate, order: DESC}) {
                edges {
                    node {
                        _key
                        title
                        artist
                        slug {
                            current
                        }
                        cover {
                            asset {
                                gatsbyImageData(
                                    aspectRatio: 1,
                                    width: 600,
                                )
                            }
                            alt
                        }
                    }
                }
            }
        }
    `)

    const seo = {
        title: `Music`,
        description: `Steve Treseler's music projects.`
    }

    return (
        <Layout seo={seo}>
            <PageTitle title="Music" />
            <Wrapper>
                {data.allSanityMusic.edges.map(musicItem => {
                    return (
                        <MusicItem input={musicItem.node} key={musicItem.node._key} />
                    )
                })}
            </Wrapper>
        </Layout>
    )
}

export default MusicPage

const Wrapper = styled.section`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
`